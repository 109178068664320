#mdl-update-scheduler {
    max-width: 650px;
    width: 100%;
}

#mdl-update-scheduler .modal-content {
    overflow-y: scroll !important;
}

@media (max-width: 450px) {
    #mdl-update-scheduler  {
        max-width: calc(100% - 30px);
    }
}