
/* ------------------------------------------------------------------------------- */
    /* Styles for Building History */
    .building-history-title-container{
        display:flex;
        justify-content:flex-start;
        margin-bottom:30px;
        padding: 8px 0;
    }

    .border-bottom{
        border-bottom: 2px solid grey;
    }
    .border-top{
        border-top: 2px solid grey;
    }
    .comment-text span{
        font-size:12px;
        display: block;
        height: auto;
    }
    .comment-spacing{
        padding:4px 1rem;
    }
    .unit-number-spacing{
        padding:4px 0;
        font-weight:bold;
        font-size:16px;
    }
    .no-data{
        text-align: center;
        align-self: center;
        color: gray;
    }

        .building-name-spacing {
            font-weight: bold;
            font-size: 15px;
        }

                .building-date-spacing {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-weight: 600;
                    font-size: 15px;
                }
        .building-history{
            display: grid;
        }
    .order-history-item span:first-of-type{
        font-weight:bold;
        font-size:13px
    }
    .row-p0{
        padding:0!important;
    }.building-name-title{
        font-weight:bold;
        padding-right:4px;
        font-size:14px;
    }.flex{
        display:flex;
    }
    .flex-column{
        flex-direction:column;
    }
    .text-center{
        text-align:center;
    }
    .bg-red{
        background-color:red;
    }
    .grid-border{
        border:1px solid #000000a3;
        border-bottom:none;
        border-right:none;
    }
    .column-border{
        border-right:1px solid #000000a3;
        border-bottom:1px solid#000000a3;
    }
    .w-60{
        width:60%
    }
    .w-40{
        width:40%
    }
    .justify-content-right{
        justify-content:flex-end;
    }.legend-spacing{
        padding: 4px 8px;
        outline:1px solid #000000a3;
    }
    .light-activity{
        background-color:#FFFF00 ;
    }
    .moderate-activity{
        background-color:#FFC000 ;
    }
    .heavy-activity{
        background-color:#FF0000 ;
    }
    .building-name{
        font-size:22px;

    }
    div.order-history-item span{
    font-size:12px;
    text-align: center;
}
    .comment-text{
        color: #0043FF;
        font-weight:bold;
    }
.app-card-header{
    padding:8px 0;
}


    #mdl-unit-history{
        width:80%;
    }
@media only screen and (max-width:550px){
    .app-card-header{
        flex-direction:column;
    }
}
.sortby_options{
    padding:0 8px
}

.text-align-center{
    text-align:center;
}
.app-content-action-right{
    display: flex;
    align-items: center;
}
/* body{
	background:rgb(255,255,255)!important;
} */


.first-column-spacing-report{
    /* padding:8px 16px 8px 32px; */
    font-weight: 600;
    padding: 18px 8px;
    color: #000000;
    text-align: left;
    /* border-right: 1px solid #0000006e; */
	/* border-top: 1px solid #0000006e; */
	/* border-left: 1px solid #0000006e; */
	/* border-bottom: 1px solid #0000006e; */
    width: 130px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
}
