.app-header {
    background-color: #ffffff;
    max-height: 70px;
    padding: 15px 0;
    box-shadow: 0 2px 2px 0px rgb(0 0 0 / 10%);
    position: fixed;
    width: 100%;
    z-index: 990;
}

.app-main {
    padding-top: 70px;
    position: relative;
}

.app-header-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.app-header-logo {
    display: flex;
    align-items: center;
    gap:56px;
    margin: 0;
}

.mobile-nav-toggler {
    cursor: pointer;
    margin-left: 25px;
    display: none;
}

.loggedin-user {
    display: flex;
    align-items: center;
}

.logout-img {
    display: none;
    vertical-align: middle;
}

.logout-img img {
    max-width: 20px;
}

.ui.inverted.red.button, .ui.inverted.red.buttons .button{
    box-shadow:none!important;
}

.btn-request-header{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius:20px!important;
    box-shadow:none!important;
    /* margin-inline: 5px !important; */
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}
button.ui.red.inverted.button.btn-request-header {
    padding: 7px 15px !important;
    /* margin-right: 20px !important; */
}


.loggedin-user-short {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #00c4ff;
    color: #ffffff;
    font-size: 12px;
    display: flex;
    align-items: center;
    /* margin-left: 20px !important; */
    justify-content: center;
}

.app-header-logo, .app-content-search {
    margin-left: 10px;
}

.loggedin-username {
    margin-left: 10px;
}

.logout-content, .update-pass {
    display: flex;
    align-items: center; /* Align items vertically in center */
    gap: 5px; /* Space between icon and text */
    font-size: 14px; /* Adjust as needed */
    margin-right: 10px;
    margin-left: 5px;
    cursor: pointer;
}

.logout-content, .update-schulder-profile {
    display: flex;
    align-items: center; /* Align items vertically in center */
    gap: 5px; /* Space between icon and text */
    font-size: 14px; /* Adjust as needed */
    margin-right: 10px;
    margin-left: 5px;
    cursor: pointer;
}

.requests-contain {
    margin-right: 20px;
    margin-left: 5px;
    cursor: pointer;
    font-size:14px;
    position: relative;
}

.requests-count {
    display: inline-block;
    font-size: 11px;
    position: relative;
    top: -7px;
    font-weight: 600;
    line-height: 1;
    margin-left: 3px;
}

.requests-contain img {
    max-width: 36px;
}

/* .logout-content img, .update-pass img {
    margin-right: 5px;
} */

.impersonate-btn button {
    padding: 10px !important;
    font-size: 12px !important;
}

.impersonate-btn button img {
    width: 25px;
}

i.user.circle.big.icon {
    color: #dcddde !important;
}

.ui.inverted.red.button{
    color:red !important;
}
.ui.inverted.red.button:hover{
    color:white !important;
}

@media (max-width: 767px) {
    .app-header-logo img {
        max-width: 150px;
    }

    .mobile-nav-toggler {
        display: block
    }

    .loggedin-username {
        display: none;
    }
}

@media (max-width: 480px) {
    .app-header-logo img {
        max-width: 130px;
    }
    
    .logout-content,
    .update-pass {
        padding: 3px 5px;
    }

    .logout-content span,
    .update-pass span {
        display: none;
    }

    .logout-content span.logout-img {
        display: block;
    }
    
    .logout-content img, .update-pass img {
        margin-right: 0;
    }
}

.ui.search.selection.dropdown{
    
}
/* .ui.floating.dropdown.link.item{
    display:flex;align-items:center;flex-direction: row-reverse;
} */